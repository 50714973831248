import React, { FC } from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';

import { ITextWithImageProps } from './model';

import './TextWithImage.scss';

const TextWithImage: FC<ITextWithImageProps> = ({
  topText,
  middleText,
  bottomText,
  image,
  bgColor,
  sectionTheme,
  imageOnTheLeft,
}) => (
  <section
    className={classnames('text-with-image', {
      [`text-with-image--${sectionTheme}`]: sectionTheme,
      [`text-with-image--image-on-the-left`]: imageOnTheLeft,
      [`text-with-image--${bgColor?.label}-bg`]: bgColor?.label,
    })}
  >
    <div className="text-with-image__container">
      <DangerouslySetInnerHtml className="text-with-image__top-text" html={topText} />
      {middleText || image?.[0]?.properties?.image ? (
        <div className="text-with-image__middle">
          <DangerouslySetInnerHtml className="text-with-image__middle-text" html={middleText} />
          <GatsbyImage
            className="text-with-image__middle-image"
            image={image?.[0]?.properties?.image}
            alt={image?.[0]?.properties?.imageAlt}
            isLazyLoading={false}
            objectFit="contain"
          />
        </div>
      ) : null}
      <DangerouslySetInnerHtml className="text-with-image__bottom-text" html={bottomText} />
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentTextWithImage on TTextWithImage {
    properties {
      topText
      middleText
      bottomText
      bgColor {
        label
      }
      sectionTheme
      imageOnTheLeft
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default TextWithImage;
