import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { ArticlePageTypes } from '@shared/types/umbraco/content/articlePage';

import Video from 'common/Video';
import TextBlock from 'common/TextBlock';
import BodyRenderer from 'common/BodyRenderer';
import Quote from 'common/Quote';
import PageSchema from 'common/PageSchema';
import { Container, Layout } from 'layout';
import ArticleDetailsBanner from 'components/ArticleDetailsBanner';
import RelatedArticles from 'components/RelatedArticles';
import CustomCarousel from 'components/CustomCarousel';
import FaqSection from 'components/FaqSection';
import ImageBlock from 'components/ImageBlock';
import ArticleStepList from 'components/ArticleStepList';
import DownloadButton from 'components/DownloadButton';
import SignUp from 'components/SignUp';
import ArticleRecommendedProducts from 'components/ArticleRecommendedProducts';
import InfoBanner from 'components/InfoBanner';
import TextSection from 'components/TextSection';
import ProductListingBanner from 'components/ProductListingBanner';
import RetailerBlock from 'common/RetailersBlock';
import BuyNowBlock from 'common/BuyNowBlock';
import TextWithImage from 'components/TextWithImage';

import './ArticlePage.scss';

const articleBlocks = {
  'PreText Block': ({ properties }, keyId) => (
    <TextBlock key={keyId} text={properties.text} className="pre-text-block " />
  ),
  'Text Block': ({ properties }, keyId) => <TextBlock key={keyId} text={properties.text} />,
  Video: ({ properties }, keyId) => <Video key={keyId} {...properties} />,
  Carousel: ({ properties }, keyId) => <CustomCarousel key={keyId} {...properties} />,
  'FAQ Section': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Image Block': ({ properties }, keyId) => <ImageBlock key={keyId} {...properties} />,
  'Recommended Products': ({ properties }, keyId) => (
    <ArticleRecommendedProducts key={keyId} {...properties} />
  ),
  'Step List': ({ properties }, keyId) => <ArticleStepList key={keyId} properties={properties} />,
  'Download Button': ({ properties }, keyId) => (
    <DownloadButton key={keyId} properties={properties} />
  ),
  'Quote Block': ({ properties }, keyId) => <Quote key={keyId} {...properties} />,
  'Retailer Block': ({ properties }, keyId) => (
    <RetailerBlock key={keyId} {...properties} variant="article" />
  ),
  'Buy Now Block': ({ properties }, keyId) => <BuyNowBlock key={keyId} {...properties} />,
};

const additionalBlocks = {
  'Related Articles': ({ properties }, keyId) => (
    <RelatedArticles key={keyId} {...properties} className="pre-text-block" />
  ),
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  'Product Listing Banner': ({ properties }, keyId) => (
    <ProductListingBanner key={keyId} {...properties} />
  ),
  'FAQ Section': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Recommended Products': ({ properties }, keyId) => (
    <ArticleRecommendedProducts key={keyId} {...properties} />
  ),
  'Text With Image': ({ properties }, keyId) => <TextWithImage key={keyId} {...properties} />,
};

const ArticlePage: FC<ArticlePageTypes.IProperties> = ({
  pageContext: { breadCrumbs, relatedArticlesLinks, link, searchUrl },
  data: {
    page: {
      nodes: [
        {
          title,
          titleSize,
          titleSizeMobile,
          description,
          image,
          defaultCompositions: {
            footer,
            header,
            waiSettings,
            articleSettings,
            siteSettings,
            brandSettings,
            singupFormBaner,
            warning,
            signUpPopup,
            signUpFormPopup,
          },
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          body,
          additionalContent,
          alternateUrls,
          hideShareButtons,
          bannerBgColor,
          pageTheme,
          ogImageUrl,
        },
      ],
    },
    relatedArticles,
    recommendedProducts,
    relatedProducts,
  },
}) => {
  const imageUrl = ogImageUrl || image?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = image?.[0]?.properties?.imageAlt;
  const isSubNavigationPresent = useMemo(() => {
    const activeNavItem = header?.navigation?.find(
      (item) =>
        item?.properties?.link?.[0] &&
        item.properties.link[0].properties.link[0].url !== '/' &&
        link.includes(item.properties.link[0].properties.link[0].url)
    );

    return !!activeNavItem?.properties?.subNavigation.length;
  }, []);

  return (
    <Layout
      {...{
        footer,
        header,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        siteSettings,
        brandSettings,
        imageUrl,
        imageAlt,
        skipText: waiSettings?.skipText,
        warning,
        link,
        searchUrl,
        alternateUrls,
        signUpPopup,
        singupFormBaner,
        pageClassName: pageTheme,
        signUpFormPopup,
      }}
    >
      <div className="article-details-page">
        <ArticleDetailsBanner
          title={title}
          titleSize={titleSize}
          titleSizeMobile={titleSizeMobile}
          description={description}
          image={image}
          breadCrumbs={breadCrumbs}
          {...(!hideShareButtons && { shareButtons: articleSettings?.shareButtons })}
          shareTitle={articleSettings?.shareTitle}
          isSubNavigationPresent={isSubNavigationPresent}
          bannerBgColor={bannerBgColor}
        />
        <Container fluid>
          <div className="article-details-page__body">
            {body?.length ? (
              <div className="article-details-page__main-column">
                <BodyRenderer
                  bodyData={body}
                  bodyStructure={articleBlocks}
                  bodyItemProps={{
                    ...{
                      waiSettings,
                      recommendedProducts,
                      usePriceSpider: siteSettings?.usePriceSpider,
                      isEanProductId: siteSettings?.isEanProductId,
                    },
                  }}
                />
              </div>
            ) : null}
            <div className="article-details-page__sidebar" />
          </div>
        </Container>
        {additionalContent?.length ? (
          <BodyRenderer
            bodyData={additionalContent}
            bodyStructure={additionalBlocks}
            bodyItemProps={{
              ...{ relatedArticles, relatedArticlesLinks, singupFormBaner, relatedProducts },
            }}
          />
        ) : null}
        <PageSchema
          siteSettings={siteSettings}
          brandSettings={brandSettings}
          seoData={{
            seoMetaKeywords,
            seoMetaTitle,
            seoMetaDescription,
          }}
          additionalData={{ title, image: image?.[0]?.properties?.image?.fallbackUrl }}
          type="Article"
          path={link}
        />
      </div>
    </Layout>
  );
};

export default ArticlePage;

export const query = graphql`
  query ArticleQuery(
    $link: String
    $relatedArticlesLinks: [String]
    $recommendedProductsLinks: [String]
    $relatedProductLinks: [String]
  ) {
    recommendedProducts: allProduct(filter: { link: { in: $recommendedProductsLinks } }) {
      nodes {
        link
        ean
        useRedirects
        title
        cardLink {
          url
        }
        images {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        shortDescription
        cardDescription
        cardImage {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        cardRetailers {
          properties {
            retailers {
              ...RetailersFragment
            }
          }
        }
      }
    }
    relatedArticles: allArticle(filter: { link: { in: $relatedArticlesLinks } }) {
      nodes {
        link
        tags {
          isCategory
          isLabel
          title
        }
        title
        lang
        date
        dateSufix
        image {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
      }
    }
    relatedProducts: allProduct(filter: { link: { in: $relatedProductLinks } }) {
      nodes {
        link
        ean
        tags {
          isCategory
          isFamily
          color {
            label
          }
          isLabel
          title
        }
        title
        shortDescription
        cardDescription
        cardLink {
          url
        }
        images {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    page: allArticle(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        title
        titleSize
        titleSizeMobile
        lang
        description
        date
        dateSufix
        image {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 520, srcSetBreakpoints: [360, 420, 480]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        additionalContent {
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TRelatedArticles {
            ...FragmentRelatedArticles
          }
          ... on TInfoBanner {
            ...FragmentInfoBanner
          }
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TProductListingBanner {
            ...FragmentProductListingBanner
          }
          ... on TFAQSection {
            ...FragmentFaqSection
          }
          ... on TTextWithImage {
            ...FragmentTextWithImage
          }
        }
        body {
          ...FragmentArticleBody
        }
        hideShareButtons
        bannerBgColor {
          label
        }
        pageTheme
        defaultCompositions {
          siteSettings {
            lang
          }
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          waiSettings {
            skipText
            ariaPrev
            ariaNext
          }
          articleSettings {
            shareTitle
            shareButtons {
              properties {
                isShow
                ariaLabel
                variant
              }
            }
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
        }
      }
    }
  }
`;
